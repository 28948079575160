import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled, { css } from 'styled-components'
import ReactTooltip from 'react-tooltip'

// helpers
import { up, addResponsivity, desktop, addFont } from '../lib/styles'

// components

import Col from '../components/Col'
import Title from '../components/Title'
import Gap from '../components/Gap'
import Row from '../components/Row'
import Link from '../components/Link'
import Text from '../components/Text'
import SEO from '../components/SEO'

import { Page, PageContent } from '../components/Page'
import ShareWidget from '../components/ShareWidget'
import SuggestionsFooter from '../components/SuggestionsFooter'
import partner1LogoPath from '../data/images/partner-logo-cs.svg'
import partner2LogoPath from '../data/images/partner-logo-erste.svg'

const RelativeWrapper = styled.div`
  ${({ theme: { colors } }) =>
    css`
      position: relative;
    `}
`

const TooltipWrapper = styled.div`
  .tooltip-overwrite {
    border-radius: 0;
    background: black;
    max-width: 300px;
    ${addFont('public')}
    line-height: 150%;
  }
`
const PartnerLogo = styled.img`
  ${({ kind }) =>
    kind === '1'
      ? css`
          width: 97px;
          height: 40px;

          ${desktop} {
            width: 103px;
            height: 43px;
          }
        `
      : css`
          width: 91px;
          height: 40px;
          ${desktop} {
            width: 97px;
            height: 43px;
          }
        `}
`

const HeroImageWrapper = styled.div`
  ${({ theme: { colors, mobilePageSidePadding } }) => css`
    margin: 0 -${mobilePageSidePadding};
    ${up('mobile')} {
      margin: 0;
    }
  `}
`
const GoldCircle = styled.div`
  ${({ theme: { colors } }) =>
    css`
      position: absolute;

      background: ${colors.gold};

      border-radius: 700px;
      top: 0;
      left: 50%;
      z-index: -2;

      transform: translate(-50%, 42px);
      width: 84vw;
      height: 84vw;

      max-width: 327px;
      max-height: 327px;

      ${up(560)} {
        max-width: 380px;
        max-height: 380px;
        width: 70vw;
        height: 70vw;
      }

      ${up('mobile')} {
        transform: translate(-50%, -24px);
        max-width: 700px;
        max-height: 700px;
      }
    `}
`

export default function ArticleTemplate({
  data,
  path,
  location: { pathname },
  ...rest
}) {
  const { article } = data
  const { frontmatter, body } = article

  const featuredImg = frontmatter.heroImage?.image?.full
  const fbShareImg = frontmatter.heroImage?.image?.fbShare

  // console.log('fbShareImg', fbShareImg?.images?.fallback?.src)

  return (
    <>
      <SEO
        title={`${article.frontmatter.title}`}
        socialTitle={article.frontmatter.socialTitle}
        description={article.frontmatter.metaDesc}
        socialDescription={article.frontmatter.metaDescSocials}
        isArticle
        image={fbShareImg?.images?.fallback?.src}
        pathname={pathname}
      />

      <Page>
        <PageContent>
          <Gap gap="24px" />
          <Row
            justifyContent="space-between"
            alignItems="center"
            responsive
            bp={1000}
          >
            <Link to={Link.HOMEPAGE}>
              <Title
                as="div"
                color="black"
                weight="700"
                size="22px"
                mobileSize="14px"
                lineHeight="32px"
                mobileLineHeight="150%"
              >
                Jak vybudovat a řídit firmu, která vydrží 100 let
              </Title>
            </Link>
            <Gap gap="16px" />
            <Row alignItems="center" responsive>
              <Text
                color="#BFBFBF"
                font="public"
                weight="500"
                size="13px"
                mobileSize="11px"
                lineHeight="150%"
                letterSpacing="1px"
                upperCase
              >
                partner speciálu
              </Text>
              <Gap gap="24px" mobileGap="4px" />

              <Row alignItems="center">
                <a
                  title="Česká spořitelna pro Firmy a podnikatele"
                  href=" https://www.csas.cz/cs/firmy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PartnerLogo
                    src={partner1LogoPath}
                    alt="Logo Česká spořitelna"
                    kind="1"
                  />
                </a>
                <Gap gap="32px" mobileGap="40px" />
                <a
                  title="Erste private banking"
                  href="https://www.ersteprivatebanking.cz/cs/sprava-majetku"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PartnerLogo
                    kind="2"
                    src={partner2LogoPath}
                    alt="Logo Erste private banking"
                  />
                </a>
              </Row>
            </Row>
          </Row>
          <Gap gap="72px" mobileGap="40px" />
          <Col alignSelf="center" maxWidth="960px" width="100%">
            <RelativeWrapper>
              <Title.ArticleTitle>{frontmatter.title}</Title.ArticleTitle>
              <GoldCircle />
            </RelativeWrapper>
            <Gap gap="40px" mobileGap="16px" />
            <HeroImageWrapper data-tip={frontmatter.heroImageAlt}>
              <GatsbyImage
                image={featuredImg}
                alt={frontmatter.heroImageAlt}
                loading="eager"
              />
            </HeroImageWrapper>
            <TooltipWrapper>
              <ReactTooltip
                className="tooltip-overwrite"
                arrowColor="transparent"
              />
            </TooltipWrapper>
            <Gap gap="40px" mobileGap="32px" />
            <Col maxWidth="720px" alignSelf="center" width="100%">
              <MDXRenderer>{body}</MDXRenderer>

              <Gap gap="0" mobileGap="8px" />

              <ShareWidget
                colorized
                path={pathname}
                what="článek"
                title={frontmatter.title}
              />

              <Gap gap="56px" mobileGap="47px" />
            </Col>
          </Col>
        </PageContent>
      </Page>

      <SuggestionsFooter hideArticle={path} />
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    article: mdx(id: { eq: $id }) {
      ...MdxArticleFragment
    }
  }
`
